import Table from 'components/Table/Table'
import { useAppSelector } from 'hooks/redux'
import React, { FC, lazy, Suspense, useState } from 'react'
import { licensesColumns } from './constants'
import './LicensesTable.scss'
import CustomDropdown from 'components/CustomDropdown/CustomDropdown'
import { ModalStateOpenType } from 'pages/Licenses/Licenses'
import { ILicense } from 'types/license'
import LicensesTableSort from './components/LicensesTableSort'
import { getLicensesTableSelector } from 'store/licenses/licenses.selectors'
import { useSort } from 'hooks/useSort'

const LicensesPreview = lazy(() => import('../LicensesPreviewDocument/LicensesPreviewDocument'))

interface Props {
  openModalHandler: (condition: ModalStateOpenType, license: ILicense) => void
  downloadFile: (fileUrl: string) => void
  openModal: (condition: ModalStateOpenType, item?: ILicense) => void
}

interface IPreviewState {
  item: ILicense | null
  isOpen: boolean
}

const LicensesTable: FC<Props> = ({ openModal, downloadFile, openModalHandler }) => {
  const [previewState, setPreviewState] = useState<IPreviewState>({
    isOpen: false,
    item: null,
  })
  const { authors, companies, items } = useAppSelector(getLicensesTableSelector)
  const { changeSortState, filteredItems, sort } = useSort(items, {
    author: 'all',
    company: 'all',
  })

  const previewCloseHandler = () => {
    setPreviewState((prevState) => ({ ...prevState, isOpen: false }))
  }

  const previewOpenHandler = (item: ILicense) => {
    setPreviewState({ item, isOpen: true })
  }

  const extendedColumns = [
    ...licensesColumns,
    {
      dataField: 'menu',
      formatter(_, row: ILicense) {
        return (
          <CustomDropdown
            previewOpenHandler={previewOpenHandler}
            downloadTitle="Скачать файл ключа"
            item={row}
            downloadFile={downloadFile}
            openModalHandler={openModalHandler}
          />
        )
      },
      text: '',
    },
  ]

  return (
    <>
      <LicensesTableSort
        openModal={openModal}
        btnTitle="Создать лицензионный ключ"
        firstSortTitle="Название организации:"
        secondSortTitle="Автор:"
        changeSortState={changeSortState}
        sort={sort}
        authors={authors}
        companies={companies}
      />
      <div className="d-flex flex-column justify-content-between mt-3 flex-grow-1 licenses__table_wrapper">
        <Table
          columns={extendedColumns}
          data={filteredItems}
          paginationProps={{
            sizePerPage: 6,
          }}
          keyField="id"
          tableProps={{
            rowClasses: 'licenses__table_row',
          }}
        />
      </div>
      <Suspense>
        <LicensesPreview
          close={previewCloseHandler}
          isOpen={previewState.isOpen}
          item={previewState.item}
        />
      </Suspense>
    </>
  )
}

export default LicensesTable
