import './RadioField.scss';

import { useField } from 'formik';

import { logicElements } from './constants';
import { RadioFieldProps } from './types';

export const RadioField = ({ scope }: RadioFieldProps) => {
  const [{ value }, , helpers] = useField(`${scope}.logicType`);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="radio-field"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {logicElements.map(({ label, value: valueElement }) => {
        const scopeKey = `${scope}-checkbox-${valueElement}`;
        return (
          <label htmlFor={scopeKey} key={scopeKey} className="radio-field__box">
            <input
              id={`${scope}-checkbox-${valueElement}`}
              type="radio"
              value={valueElement}
              checked={valueElement === value}
              onChange={() => helpers.setValue(valueElement)}
              className="radio-field__input"
            />
            {label}
          </label>
        );
      })}
    </div>
  );
};

RadioField.defaultProps = { scope: 'rules' };
