import React, { FC, useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import shapesImg from 'assets/images/shapes.svg'
import './UserDropdown.scss'
import { IUserData } from 'types/user'
import { ModalStateOpenType } from 'pages/Users/Users'

interface Props {
  item: IUserData
  openModalHandler: (modalType: ModalStateOpenType, user: IUserData) => void
}

const UserDropdown: FC<Props> = ({ item, openModalHandler }) => {
  const [isOpen, setisOpen] = useState(false)

  const toggleHandler = () => {
    setisOpen(!isOpen)
  }

  return (
    <div className="text-center">
      <Dropdown toggle={toggleHandler} isOpen={isOpen}>
        <DropdownToggle className="dropdown__btn">
          <img src={shapesImg} alt="" />
        </DropdownToggle>
        <DropdownMenu className="table__dropdown_list">
          <DropdownItem
            color="secondary"
            className="table__dropdown_item_edit"
            onClick={() => openModalHandler('edit', item)}
          >
            Редактировать
          </DropdownItem>
          <DropdownItem
            className="table__dropdown_item_delete"
            onClick={() => openModalHandler('delete', item)}
          >
            Удалить
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default UserDropdown
