import './InputParams.scss';

import { Button } from 'reactstrap';
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC, useState } from 'react';
import {
  getPowerCalculatorSchemesDefInputParams,
  getPowerCalculatorSchemesInputParams,
  getPowerCalculatorSchemesVMCost,

} from 'store/powerCalculator/powerCalculator.selectors';
import { InputParameterData } from 'types/powerCalculator';
import { uuid } from 'utils/data';

import {
  InputParameter,
  InputParameterControl,
  InputParamsView,
  InputParamsWithDefValue,
} from './components';
import { InputParamsProps } from './types';
import { setInputParams, setDefInputParams, setVmCost } from 'store/powerCalculator/powerCalculator.actions';
import { inputParamsValidation } from './validation';
import { VirtualMachineCost } from './components/VirtualMachineCost';

interface FormValue {
  defInputParams: (InputParameterData & { rate: number })[];
  inputParams: InputParameterData[];
  isEdit: boolean;
  vmCost: {
    cpu: number;
    ram: number;
    hdd: number;
    gpu: number;
  }
}
const getItem = () => ({
  value: uuid(),
  label: '',
  rate: 1,
});

export const InputParams: FC<InputParamsProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const constructorInputParams = useAppSelector(getPowerCalculatorSchemesInputParams);
  
  const constructorDefInputParams = useAppSelector(
    getPowerCalculatorSchemesDefInputParams
  );

  const constructorVMCost = useAppSelector(getPowerCalculatorSchemesVMCost);

  const dispatch = useAppDispatch();

  const handleSubmit = (
    values: FormValue,
    formikHelpers: FormikHelpers<FormValue>
  ) => {
    dispatch(setInputParams(values.inputParams));
    dispatch(setDefInputParams(values.defInputParams));
    dispatch(setVmCost(values.vmCost));

    formikHelpers.setFieldValue('isEdit', false);
  };

  const renderInputParamsList = (
    values: FormValue,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (!values.isEdit) {
      return (
        <InputParamsView
          params={[
            ...arrayHelpers.form.values.defInputParams,
            ...arrayHelpers.form.values.inputParams,
          ]}
        />
      );
    }

    if (arrayHelpers.form.values.inputParams.length) {
      return arrayHelpers.form.values.inputParams.map((parameter, index: number) => (
        <InputParameter
          key={parameter.value}
          scope={`inputParams.${index}`}
          index={index}
          arrayHelpers={arrayHelpers}
        />
      ));
    }

    return (
      <Button onClick={() => arrayHelpers.unshift(getItem())}>
        Добавить параметр
      </Button>
    );
  };


  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };
  return (

    <aside className="input-params">
      <span
        className={`btn-icon button__icon input-params__toggle_visibility bx ${isOpen ? 'bx-collapse' : 'bx bx-expand'}`}
        onClick={toggleVisibility}
      >
      </span>
      {isOpen && (<>
        {children}
        <hr />

        <Formik<FormValue>
          initialValues={{
            isEdit: false,
            inputParams: constructorInputParams,
            defInputParams: constructorDefInputParams,
            vmCost: constructorVMCost
          }}
          validationSchema={inputParamsValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors }) => (
            <Form className="input-params__form">
  
              <FieldArray
                name="inputParams"
                render={(arrayHelpers) => (
                  <>
        

                  
                    <InputParameterControl />
                    {values.isEdit && <Button type="submit" disabled={!!Object.keys(errors).length}>Сохранить</Button>}
                    {values.isEdit && (
                      <>
                         <VirtualMachineCost/>
                        <InputParamsWithDefValue />
                     
                      </>
                    )}

                    <div className="input-params__list">
                      {renderInputParamsList(values, arrayHelpers)}
                      <span className="form-error-message">{ }</span>
                    </div>
                  </>
                )}
              />


            </Form>
          )}
        </Formik></>)}
    </aside>
  );
};
