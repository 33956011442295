import './Sidebar.scss';

import { type FC, useRef } from 'react';

import { LeftResizable } from './components';
import { type SidebarProps } from './types';

export const Sidebar: FC<SidebarProps> = (props) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  return (
    <div id="sidebar" className="sidebar" ref={sidebarRef}>
      <LeftResizable sidebarRef={sidebarRef} sidebarMinWidth={37} />
      <div className="sidebar__body">{props.children}</div>
    </div>
  );
};
