import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { ILoginData, ILoginResponse, IUserData } from 'types/user'
import axiosApi from 'utils/api_helper'

export default class AuthService {
  static async fetchUserData() {
    const response: AxiosResponse<IUserData> = await axiosApi.get(`${ENDPOINTS.USER}/me`)
    return response
  }

  static async login(data: ILoginData) {
    const response: AxiosResponse<ILoginResponse> = await axiosApi.post(
      `${ENDPOINTS.AUTH}/login`,
      data
    )

    return response
  }
}
