import { FC } from 'react'
import './DownloadButton.scss'
import { getNodesBounds, getViewportForBounds, useReactFlow } from '@xyflow/react'
import { toPng } from 'html-to-image';
import { Button } from 'reactstrap';

interface Props {
}

const imageWidth = 1524;
const imageHeight = 1068;


function downloadImage(dataUrl) {
  const a = document.createElement('a');

  a.setAttribute('download', 'reactflow.png');
  a.setAttribute('href', dataUrl);
  a.click();
}


export const DownloadButton: FC<Props> = () => {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.2,
      2,
      0
    );
    const viewportContainer = document.querySelector('.react-flow__viewport') as HTMLElement;
    if (viewportContainer) {
      toPng(viewportContainer, {
        backgroundColor: '#f8f8fb',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: `${imageWidth}`,
          height: `${imageHeight}`,
          transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
        },
      }).then(downloadImage);
    }
  };


  return (
    <Button className="download-btn" onClick={onClick} size='sm' color='primary'>
      Выгрузить изображение
    </Button>
  )
}
