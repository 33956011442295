import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICertificateCreate, ICertificateExpand } from 'types/certificate'
import CertificatesService from './certificates.service'

export const createCertificateAction = createAsyncThunk(
  'certificates/add',
  async (data: ICertificateCreate) => {
    const response = await CertificatesService.createCertificate(data)
    return response.data
  }
)

interface IUpdateCertificateAction {
  certificate: ICertificateCreate
  certificateId: number
}

export const updateCertificateAction = createAsyncThunk(
  'certificates/update',
  async ({ certificate, certificateId }: IUpdateCertificateAction) => {
    const response = await CertificatesService.updateCertificate(certificate, certificateId)
    return response.data
  }
)

export const deleteCertificateAction = createAsyncThunk(
  'certificates/delete',
  async (licenseId: number) => {
    await CertificatesService.deleteCertificate(licenseId)
    return licenseId
  }
)

interface IExpandCertificateAction {
  data: ICertificateExpand
  id: number
}
export const expandCertificateAction = createAsyncThunk(
  'certificate/expand',
  async ({ data, id }: IExpandCertificateAction) => {
    const response = await CertificatesService.expandCertificate(data, id)
    return response.data
  }
)

export const getAllCertificates = createAsyncThunk('certificates/all', async () => {
  const response = await CertificatesService.getCertificates()
  return response.data
})
