import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useLicenses } from 'hooks/useLicenses'
import { useModal } from 'hooks/useModal'
import React, { lazy, Suspense, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { ILicense } from 'types/license'
import LicensesDeleteModal from './components/LicensesDeleteModal/LicensesDeleteModal'
import LicensesTable from './components/LicensesTable/LicensesTable'

export const LicenseEditModal = lazy(
  () => import('./components/LicensesEditModal/LicensesEditModal')
)

export type ModalStateOpenType = false | 'delete' | 'edit' | 'expand'
export interface IModalState {
  isOpen: ModalStateOpenType
  license: ILicense | null
}

const Licenses = () => {
  const { modal, closeModal, openModal } = useModal<ModalStateOpenType, ILicense>()
  const { getLicenses, deleteLicense, downloadFile, createOrUpdateLicense } = useLicenses()

  useEffect(() => {
    getLicenses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteHandler = async () => {
    await deleteLicense(modal.item?.id ?? 0)
    closeModal()
  }

  return (
    <>
      <BreadCrumbs secondItem="Список" title="Лицензии" />
      <Card className="border-0 flex-grow-1 mt-4">
        <CardBody className="d-flex flex-column">
          <LicensesTable
            downloadFile={downloadFile}
            openModal={openModal}
            openModalHandler={openModal}
          />
        </CardBody>
      </Card>
      <Suspense>
        <LicenseEditModal
          editItem={modal.item}
          closeHandler={closeModal}
          isOpen={modal.isOpen === 'edit'}
          createOrUpdateLicense={createOrUpdateLicense}
        />
      </Suspense>
      <LicensesDeleteModal
        closeHandler={closeModal}
        company={modal.item?.company ?? ''}
        createdAt={modal.item?.createdAt ?? ''}
        deleteHandler={deleteHandler}
        isOpen={modal.isOpen === 'delete'}
      />
    </>
  )
}

export default Licenses
