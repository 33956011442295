import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

const selectLicense = (state: RootState) => state.licenses

export const getLicensesItemsSelector = createSelector(selectLicense, (state) => state.items)

export const getLicensesCompaniesSelector = createSelector(selectLicense, (state) => {
  const companies: string[] = []

  for (const license of state.items) {
    const company = license.company
    if (!companies.includes(company)) companies.push(company)
  }
  return companies
})

export const getLicensesAuthorSelector = createSelector(selectLicense, (state) => {
  const authors: string[] = []

  for (const license of state.items) {
    if (license.user) {
      const name = license.user.name
      if (!authors.includes(name)) authors.push(name)
    }
  }
  return authors
})

export const getLicensesTableSelector = createSelector(
  selectLicense,
  getLicensesItemsSelector,
  getLicensesCompaniesSelector,
  getLicensesAuthorSelector,
  (_state, items, companies, authors) => ({
    items,
    companies,
    authors,
  })
)
