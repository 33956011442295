import './FieldBase.scss';

import { ErrorMessage, Field, useField } from 'formik';

import { FormFieldLabel } from '../../components/FormFieldLabel';
import { FieldBaseProps } from './types';

export const FieldBase = <T,>(field: FieldBaseProps<T>) => {
  const [,{error},]= useField(field.name)
  
  const className =   field.type === 'time'
  ? 'form-control form-control--time'
  : 'form-control';
  return (
    <>
      <FormFieldLabel htmlFor={field.name}>{field.label}</FormFieldLabel>
      <Field
        {...field}
        id={field.name}
        className={
          `${className} ${!!error && 'is-invalid'}`
        }
        style={{}}
        autoComplete="off"
      />
      <ErrorMessage
        name={field.name}
        component="span"
        className="form-error-message"
      />
    </>
  );
};
