import React, { FC } from 'react'
import logoImg from 'assets/images/logo.svg'
import './ModalHeader.scss'

interface Props {
  title: string
  closeHandler: () => void
}
const CustomModalHeader: FC<Props> = ({ title, closeHandler }) => {
  return (
    <div className="modal__logo_header">
      <span className="modal__close_icon" onClick={closeHandler}>
        <i className="fas fa-times" />
      </span>
      <div className="modal__title">{title}</div>
      <img src={logoImg} alt="" />
    </div>
  )
}

export { CustomModalHeader as ModalHeader }
