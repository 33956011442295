import ConfirmModal from 'components/ConfirmModal/ConfirmModal'
import './PowerCalculatorDeleteModal.scss'
import  { FC } from 'react'

interface Props {
  isOpen: boolean
  closeHandler: () => void
  deleteHandler: () => void
  schemeDescription: string

}

const PowerCalculatorDeleteModal: FC<Props> = ({
  closeHandler,
  schemeDescription,
  deleteHandler,
  isOpen,
}) => {
  return (
    <ConfirmModal closeHandler={closeHandler} isOpen={isOpen} submitHandler={deleteHandler}>
      <div>
        <div className="power-calculator-delete__title">Удалить схему</div>
        <div className="power-calculator-delete__subtitle">для {schemeDescription}?</div>
      </div>
    </ConfirmModal>
  )
}

export default PowerCalculatorDeleteModal
