import './FieldSelect.scss';

import { FormFieldLabel } from 'components/FormFieldsBuilder/components';
import { ErrorMessage, useField } from 'formik';
import { FieldSelectProps } from './types';
import {  Input } from 'reactstrap';

export const FieldSelect = <T,>(field: FieldSelectProps<T>) => {
  const [,{error},]= useField(field.name)
  const {
    options,
  } = field;

  const fieldName = field.name as string;
  const [_, { value: fieldValue }, helpers] = useField(fieldName);

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  const currentOption = options.find(
    (option) => String(option.value) === String(fieldValue)
  );

  return (
    <div>

      <FormFieldLabel htmlFor={field.name}>{field.label}</FormFieldLabel>

      <Input
        name='role'
        type='select'
        className={  `${!!error && 'is-invalid'}`}
        onChange={(event) => handleChange(event.target.value)}
        value={currentOption?.value}
      >  <option key={''} value={''}>
          Выбрать значение
        </option>
        {options.map((option) => (

          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>


      {/* <Select<Option, false>
        className="select"
        options={options}
        isCreatable={isCreatable}
        onChange={handleChange}
        value={currentOption}
        isLoading={isLoading}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        placeholder={placeholder}
        noOptionsMessage={(obj) => obj && !isLoading && 'Ничего не найдено'}
      /> */}
      <ErrorMessage
        name={field.name}
        component='span'
        className='form-error-message'
      />
    </div>
  );
};
