import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import UserDropdown from 'components/UserDropdown/UserDropDown'
import Table from 'components/Table/Table'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect } from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { deleteUser, editUser, getAllUsers, register } from 'store/users/users.actions'
import { IRegisterData, IUserData } from 'types/user'
import UsersDeleteModal from './components/UsersDeleteModal'
import UserModal from './components/UserModal'
import { usersColumns } from './UserConstants'
import './Users.scss'
import { useModal } from 'hooks/useModal'

export type ModalStateOpenType = false | 'delete' | 'edit'
export interface IModalState {
  isOpen: ModalStateOpenType
  item: IUserData | null
}

const Users = () => {
  const { closeModal, modal, openModal } = useModal<ModalStateOpenType, IUserData>()
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.users.users)

  useEffect(() => {
    dispatch(getAllUsers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const registerUserHandler = async (values: IRegisterData) => {
    return await dispatch(register(values)).unwrap()
  }

  const updateUserHandler = async (values: IRegisterData, userId: number) => {
    return await dispatch(editUser({ userData: values, userId })).unwrap()
  }

  const deleteUserHandler = async (userId: number) => {
    return await dispatch(deleteUser(userId))
  }

  const extendedUserColumns = [
    ...usersColumns,
    {
      dataField: 'edit',
      text: '',
      formatter: (_row: number, cell: IUserData) => (
        <UserDropdown item={cell} openModalHandler={openModal} />
      ),
    },
  ]

  return (
    <>
      <BreadCrumbs firstItem="Пользователи" secondItem="Список" title="Список пользователей" />

      <Card className="border-0 flex-grow-1 mt-4">
        <CardBody className="d-flex flex-column">
          <div className="text-end">
            <Button className="users__add_btn" color="primary" onClick={() => openModal('edit')}>
              Создать пользователя
            </Button>
          </div>
          <div className="d-flex flex-column justify-content-between mt-3 flex-grow-1">
            <Table
              columns={extendedUserColumns}
              data={users}
              keyField="id"
              tableProps={{
                rowClasses: 'users__table_row',
              }}
            />
          </div>
        </CardBody>
      </Card>
      <UserModal
        close={closeModal}
        modalState={modal}
        updateUser={updateUserHandler}
        registerUser={registerUserHandler}
      />
      <UsersDeleteModal
        closeHandler={closeModal}
        deleteHandler={deleteUserHandler}
        isOpen={modal.isOpen === 'delete'}
        user={modal.item}
      />
    </>
  )
}

export default Users
