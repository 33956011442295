import { DataBaseData } from 'types/powerCalculator';

export const defData: DataBaseData = {
  name: '',
  cpu: 0,
  ram: 0,
  hdd: 0,
  gpu: 0,
  cpuUseRestrictions: false,
  ramUseRestrictions: false,
  hddUseRestrictions: false,
  gpuUseRestrictions: false,
  cpuMin: 0,
  cpuMax: 0,
  ramMax: 0,
  ramMin: 0,
  hddMax: 0,
  hddMin: 0,
  gpuMax: 0,
  gpuMin: 0,
  cpuCode: "function handler(\r\n  data,\r\n  inputParams\r\n) {\r\n  const parameterIdWithNameForCalculatePower = data['parameterNameForCalculatePower'];\r\n  const loadPerUser = data.cpu / 100;\r\n  const sumAdditionalNumberRequests = Object.values(data.incomingLoad).reduce(\r\n    (currentSum, requestData) =>\r\n      currentSum + requestData.additionalNumberRequests,\r\n    0\r\n  );\r\n  const totalLoad =\r\n    loadPerUser * (inputParams[parameterIdWithNameForCalculatePower] + sumAdditionalNumberRequests);\r\n  return totalLoad;\r\n}",
  gpuCode: "function handler(data, inputParams) {\r\n  const parameterIdWithNameForCalculatePower = data['parameterNameForCalculatePower'];\r\n  const loadPerUser = data.gpu / 100;\r\n  const totalLoad = loadPerUser * inputParams[parameterIdWithNameForCalculatePower]; \r\n  return totalLoad;\r\n}",
  hddCode: "function handler(data, inputParams) {\r\n  const loadPerUser = data.hdd / 100;\r\n  const parameterIdWithDaysStorage = data['parameterWithDataDaysStorage'];\r\n  const parameterIdWithNumberGeneratedPerDay = data['parameterWithDataNumberGeneratedPerDay'];\r\n    \r\n  const totalLoad = loadPerUser * inputParams[parameterIdWithDaysStorage] *  inputParams[parameterIdWithNumberGeneratedPerDay]; \r\n  return totalLoad;\r\n}",
  ramCode: "function handler(data, inputParams) {\r\n  const parameterIdWithNameForCalculatePower = data['parameterNameForCalculatePower'];\r\n  const loadPerUser = data.ram / 100;\r\n  const totalLoad = loadPerUser * inputParams[parameterIdWithNameForCalculatePower]; \r\n  return totalLoad;\r\n}",
  conditionsSeparateVm: [],
  scalingConditions: [],
  namePreferredVm: '',
  days_storage: 0,
  incomingLoad: {},
  parameterWithDataNumberGeneratedPerDay: '',
  parameterWithDataDaysStorage: '',

  newReplicaCpu: 0,
  newReplicaRam: 0,
  newReplicaHdd: 0,
  newReplicaGpu: 0,
  groupVM: '',
  parameterNameForCalculatePower: ''
};
