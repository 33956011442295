import ConfirmModal from 'components/ConfirmModal/ConfirmModal'
import React, { FC } from 'react'

interface Props {
  isOpen: boolean
  closeHandler: () => void
  deleteHandler: () => void
  company: string
  createdAt: string | Date
}

const CertificatesDeleteModal: FC<Props> = ({
  closeHandler,
  company,
  createdAt,
  deleteHandler,
  isOpen,
}) => {
  return (
    <ConfirmModal closeHandler={closeHandler} isOpen={isOpen} submitHandler={deleteHandler}>
      <div>
        <div className="licenses__delete_title">Удалить сертификат</div>
        <div className="licenses__delete_company">для {company}?</div>
      </div>
      <div className="licenses__delete_date">
        Дата генерации: {new Date(createdAt).toLocaleDateString()}
      </div>
    </ConfirmModal>
  )
}

export default CertificatesDeleteModal
