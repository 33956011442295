import { getOutgoers, useReactFlow, Node, MarkerType } from "@xyflow/react";
import { BranchData, EdgeParams } from "types/powerCalculator";

const getEdgeStyle = (color: string) =>
({
    style: { stroke: color, strokeWidth: 3 }, "markerEnd": {
        type: MarkerType.ArrowClosed,
        color: color
    }
})

export const useUpdateBranchEdges = () => {
    const { getEdges, getNodes, updateEdge, updateNodeData } = useReactFlow();

    return (nodeId: string, nodeData: BranchData) => {
        const outgoesNodes = getOutgoers<Node, EdgeParams>({ id: nodeId }, getNodes(), getEdges());

        const defNode = outgoesNodes.find(node => node.id === nodeData.defNode)
        const nodeByCondition = outgoesNodes.find(node => node.id !== nodeData.defNode)

        if (defNode) {
            const edgeId = `xy-edge__${nodeId}-${defNode.id}${defNode.type === 'microservice' ? 'b' : ''}`;
            updateEdge(edgeId, getEdgeStyle('red'))
        } else {
            updateNodeData(nodeId, {...nodeData, defNode: null})
        }

        if (nodeByCondition) {
            const edgeId = `xy-edge__${nodeId}-${nodeByCondition.id}${nodeByCondition.type === 'microservice'? 'b' : ''}`;
            updateEdge(edgeId, getEdgeStyle('green'))
        }
    }
}
