import { createSlice } from '@reduxjs/toolkit'
import { IUserData } from 'types/user'
import { deleteUser, editUser, getAllUsers, register } from './users.actions'

interface IInitState {
  users: IUserData[]
}

const initialState: IInitState = {
  users: [],
}

const usersSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.users.push(action.payload)
    })
    builder.addCase(editUser.fulfilled, (state, action) => {
      const newUsers = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload
        }
        return user
      })
      state.users = newUsers
    })
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const newUsers = state.users.filter((user) => user.id !== action.payload)
      state.users = newUsers
    })
  },
})

export const usersReducer = usersSlice.reducer
