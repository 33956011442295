import './PowerCalculatorResultTable.scss';
import { FC } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody } from 'reactstrap';
import { CalculateResponse } from 'types/powerCalculator';
import { getFormattedNumber } from 'utils/data';

const vmResultsColumns = [{
  dataField: 'name',
  text: 'Имя'
},{
  dataField: 'cpu',
  text: 'cpu'
},{
  dataField: 'ram',
  text: 'ram'
},{
  dataField: 'hdd',
  text: 'hdd'
},{
  dataField: 'gpu',
  text: 'gpu'
}];

const serviceResultsColumns = [{
  dataField: 'name',
  text: 'Имя'
}, {
  dataField: 'cpu',
  text: 'cpu'
}, {
  dataField: 'ram',
  text: 'ram'
},
{
  dataField: 'hdd',
  text: 'hdd'
},
{
  dataField: 'gpu',
  text: 'gpu'
},
{
  dataField: 'numberReplicas',
  text: 'Кол-во реплик'
}];


const PowerCalculatorResultTable: FC<{ data: CalculateResponse }> = ({ data }) => {
  const expandRow = {
    renderer: (row: CalculateResponse['vmResults'][0]) => (
      <ul>{
        row.services.map(service =>
          <li key={service.serviceId}>{service.serviceName}{`(${service.percentDownloadCpu}|${service.percentDownloadRam})`}</li>)
      }</ul>

    ),
    showExpandColumn: true,
    expandByColumnOnly: true
  };

  return <Card className="border-0 flex-grow-1 mt-3">
    <CardBody className="d-flex flex-column">
      <div className="power-calculator-result-table">
        <span>Итог: </span>
        <div> <span> <strong>CPU</strong>: {getFormattedNumber(data.totalResult.cpu)}</span> | <span> <strong>CPU (ядра)</strong>: {getFormattedNumber(Math.ceil(data.totalResult.cpu / 100 / 2))}</span> |  <span> <strong>RAM:</strong> {getFormattedNumber(data.totalResult.ram)}</span> | <span> <strong>HDD:</strong>  {getFormattedNumber(data.totalResult.hdd || 0)}</span> | <span> <strong>GPU:</strong>  {getFormattedNumber(data.totalResult.gpu || 0)}</span>  </div>
        <br />
        <div className="power-calculator-result-table__content">

          <span>Распределение по виртуальным машинам</span>
          <BootstrapTable
            keyField='name'
            data={data.vmResults}
            columns={vmResultsColumns}
            expandRow={expandRow}
          />

          <span>Итоговая нагрузка по микросервисам </span>
          <BootstrapTable
            keyField='name'
            data={data.serviceResults}
            columns={serviceResultsColumns}
          />
        </div>
      </div>
    </CardBody>
  </Card>

}

export default PowerCalculatorResultTable;
