import './SettingConstructor.scss';

import { Button } from 'reactstrap';
import { Sidebar } from 'components/Sidebar';
import { Form, Formik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC } from 'react';
import { getPowerCalculatorSchemesSelectedNode } from 'store/powerCalculator/powerCalculator.selectors';
import { BranchData, MapMenuByType, NodeData } from 'types/powerCalculator';

import {
  BranchMenu,
  branchMenuValidation,
  BrokerMenu,
  brokerMenuValidation,
  DataBaseMenu,
  dataBaseMenuValidation,
  MicroserviceMenu,
  microserviceMenuValidation
} from '../configs/menu';
import { SettingConstructorProps } from './types';
import { setSelectedNode } from 'store/powerCalculator/powerCalculator.actions';
import { useReactFlow } from '@xyflow/react';
import { useUpdateBranchEdges } from 'hooks/useUpdateBranchEdges';


const mapMenuByType: MapMenuByType = {
  branch: <BranchMenu />,
  dataBase: <DataBaseMenu />,
  microservice: <MicroserviceMenu />,
  broker: <BrokerMenu />,
};

const mapValidationByType = {
  branch: branchMenuValidation,
  dataBase: dataBaseMenuValidation,
  microservice: microserviceMenuValidation,
  broker: brokerMenuValidation,
};

export const SettingConstructor: FC<SettingConstructorProps> = ({

}) => {
  const { updateNodeData } = useReactFlow();
  const selectedNode = useAppSelector(getPowerCalculatorSchemesSelectedNode);
  const dispatch = useAppDispatch();
  const onUpdateBranch = useUpdateBranchEdges()

  if (!selectedNode) {
    return null;
  }

  return (
    <Sidebar>
      <Formik<NodeData>
        initialValues={selectedNode.data}
        onSubmit={(value) => {
          updateNodeData(selectedNode.id, value)
          if(selectedNode.type === 'branch') {
            onUpdateBranch(selectedNode.id, value as BranchData)
          }
        }}
        onReset={() => {
          dispatch(setSelectedNode(null));
        }}
        validationSchema={mapValidationByType[selectedNode.type]}
        enableReinitialize
      >
        {({ errors}) => (
          <>
          < Form className="setting-constructor__form" id="settingConstructor">
            <div className="setting-constructor__content">
              {mapMenuByType[selectedNode.type]}
            </div>
          </Form>
          <div className="setting-constructor__controls">
        <Button
          form="settingConstructor"
          secondary
          type="reset"
          className="setting-constructor__controls-button"
        >
          Отмена
        </Button>
        <Button
          form="settingConstructor"
          type="submit"
          className="setting-constructor__controls-button"
          color='primary'
          disabled={!!Object.keys(errors).length}
        >
          Сохранить
        </Button>
      </div>
          </>
        )}

      </Formik>

      
    </Sidebar>
  );
};
