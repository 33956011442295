import './FieldWithLabelAndValue.scss';

import classNames from 'classnames';
import { FieldBase } from 'components/FormFieldsBuilder/fields/FieldBase';
import { useField } from 'formik';
import { FC } from 'react';

import { FieldWithLabelAndValueProps } from './types';

export const FieldWithLabelAndValue: FC<FieldWithLabelAndValueProps> = ({
  scope,
}) => {
  const [{ value }, { error, touched }, { setValue }] = useField(
    `${scope}.value`
  );

  return (
    <div className="field-with-label-and-value">
      <input
        type="text"
        autoFocus
        className={classNames('field-with-label-and-value__value', {
          error: !!error && touched,
        })}
        placeholder="name_parameter"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="field-with-label-and-value_label">
        <div className="field-with-label-and-value__field">
          <FieldBase
            name={`${scope}.label`}
            label=""
            type="text"
            placeholder="Введите название поля"
          />
        </div>
      </div>
    </div>
  );
};
