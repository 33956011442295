import { createSlice } from '@reduxjs/toolkit'
import { ILicense } from 'types/license'
import {
  createLicenseAction,
  deleteLicenseAction,
  getAllLicenses,
  updateLicenseAction,
} from './licenses.actions'

interface IInitState {
  items: ILicense[]
}

const initialState: IInitState = {
  items: [],
}

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllLicenses.fulfilled, (state, action) => {
      state.items = action.payload
    })
    builder.addCase(createLicenseAction.fulfilled, (state, action) => {
      state.items.push(action.payload)
    })
    builder.addCase(updateLicenseAction.fulfilled, (state, action) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    })
    builder.addCase(deleteLicenseAction.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload)
    })
  },
})

export default licensesSlice

export const licensesReducer = licensesSlice.reducer
