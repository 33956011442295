type RuleLogicType = 'or' | 'and';
export const logicElements: { label: string; value: RuleLogicType }[] = [
  {
    label: 'И',
    value: 'and',
  },
  {
    label: 'Или',
    value: 'or',
  },
];
