import './LeftResizable.scss';

import {
  type FC,
  type MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
} from 'react';

import { type LeftResizableProps } from './types';

const SIDEBAR_LOCALSTORAGE_WIDTH_NAME = 'SIDEBAR_LOCALSTORAGE_WIDTH_NAME';

export const LeftResizable: FC<LeftResizableProps> = ({
  sidebarRef,
  sidebarMinWidth = 0,
}) => {
  const initPos = useRef<number>(0);
  const initSize = useRef<number>(0);

  const resize = (event: MouseEvent) => {
    event.preventDefault();
    if (sidebarRef.current !== null) {
      const newWidth = initSize.current + initPos.current - event.clientX;
      sidebarRef.current.style.width = `${
        newWidth > sidebarMinWidth ? newWidth : sidebarMinWidth
      }px`;
      localStorage.setItem(
        SIDEBAR_LOCALSTORAGE_WIDTH_NAME,
        newWidth.toString()
      );
    }
  };

  const onMouseDown = (event: ReactMouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    initPos.current = event.clientX;
    if (sidebarRef.current !== null)
      initSize.current = sidebarRef.current.offsetWidth;
    window.addEventListener('mousemove', resize);
  };

  const onMouseUp = (event: MouseEvent) => {
    event.preventDefault();
    window.removeEventListener('mousemove', resize);
  };

  useEffect(() => {
    if (sidebarRef.current !== null) {
      const savedWidth =
        localStorage.getItem(SIDEBAR_LOCALSTORAGE_WIDTH_NAME) ?? 450;
      sidebarRef.current.style.width = `${savedWidth}px`;
    }
  }, [sidebarRef]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <div
      className="left-resizable"
      onMouseDown={onMouseDown}
      role="presentation"
    />
  );
};
