import './Tools.scss';

import { ReactComponent as DataBase } from 'assets/sass/icons/dataBase.svg';
import { ReactComponent as Microservice } from 'assets/sass/icons/microservice.svg';
import { ReactComponent as Broker } from 'assets/sass/icons/broker.svg';
import { useDnD } from 'context/DnDContext';
import { FC } from 'react';
import { TypeNode } from 'types/powerCalculator';
import { calculatePath } from 'utils/reactFlow';

import { ToolsProps } from './types';

export const Tools: FC<ToolsProps> = () => {
  const [, setType] = useDnD();

  const onDragStart = (event, nodeType: TypeNode) => {
    setType(nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="tools">
      <div
        className="node dndnode microservice"
        onDragStart={(event) => onDragStart(event, 'microservice')}
        draggable
      >
        <Microservice />
      </div>
      <div
        className="node dndnode dataBase"
        onDragStart={(event) => onDragStart(event, 'dataBase')}
        draggable
      >
        <DataBase />
      </div>
      <div
        className="node dndnode broker"
        onDragStart={(event) => onDragStart(event, 'broker')}
        draggable
      >
        {<Broker />}
      </div>
      <div
        className="node dndnode dndnode--branch"
        onDragStart={(event) => onDragStart(event, 'branch')}
        draggable
      >
        <svg width={50} height={40} className="shape-svg">
          <g transform="translate(2, 2)">
            <path
              d={calculatePath(50 - 4, 40 - 4)} // Генерация пути с учетом отступов
              fill="#fdd854"
              strokeWidth="3"
              stroke="#2a3042"
              fillOpacity="0.8"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
