import { createSlice } from '@reduxjs/toolkit'
import { ICertificate } from 'types/certificate'
import {
  createCertificateAction,
  deleteCertificateAction,
  expandCertificateAction,
  getAllCertificates,
  updateCertificateAction,
} from './certficates.actions'

interface IInitState {
  items: ICertificate[]
}

const initialState: IInitState = {
  items: [],
}

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllCertificates.fulfilled, (state, action) => {
      state.items = action.payload
    })
    builder.addCase(createCertificateAction.fulfilled, (state, action) => {
      state.items.push(action.payload)
    })
    builder.addCase(updateCertificateAction.fulfilled, (state, action) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    })
    builder.addCase(expandCertificateAction.fulfilled, (state, action) => {
      state.items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    })
    builder.addCase(deleteCertificateAction.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload)
    })
  },
})

export default certificatesSlice

export const certificatesReducer = certificatesSlice.reducer
