import RouteElement from 'components/RouteElement/RouteElement'
import { nameToken } from 'constants/app_constants'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { adminRoutes, privateRoutes, publicRoutes } from 'navigation'
import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { fetchUserData } from 'store/auth/auth.actions'

function App() {
  const { isAuth, role } = useAppSelector((state) => ({
    isAuth: state.auth.isAuth,
    role: state.auth?.role,
  }))
  const token = localStorage.getItem(nameToken)
  const redirectUrl = isAuth ? '/licenses' : '/login'
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (token) {
      dispatch(fetchUserData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Routes>
        {publicRoutes.map(({ path, component, ...rest }, idx) => (
          <Route path={path} element={<RouteElement component={component} {...rest} />} key={idx} />
        ))}

        {role === 'admin' &&
          adminRoutes.map(({ path, component, ...rest }, idx) => (
            <Route
              path={path}
              element={<RouteElement component={component} {...rest} />}
              key={idx}
            />
          ))}

        {isAuth &&
          privateRoutes.map(({ path, component, ...rest }, idx) => (
            <Route
              path={path}
              element={<RouteElement component={component} {...rest} />}
              key={idx}
            />
          ))}

        <Route path="*" element={<Navigate to={redirectUrl} />} />
      </Routes>
    </>
  )
}

export default App
