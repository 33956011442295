import './InputParameter.scss';

import { FieldWithLabelAndValue } from 'components/FormFieldsBuilder/fields/FieldWithLabelAndValue';
import { FC } from 'react';

import { InputParameterProps } from './types';
import { getNewInputParameter } from './constants';


export const InputParameter: FC<InputParameterProps> = ({
  scope,
  index,
  arrayHelpers,
}) => {
  return (
    <div className="input-parameter">
      <div className="input-parameter__label">
        <FieldWithLabelAndValue scope={scope} />
      </div>

      <div className="input-parameter__controls">
        <span
          className="btn-icon button__icon button__icon--trash bx bx-trash"
          onClick={() => arrayHelpers.remove(index)}
        />
        <span
          className="btn-icon button__icon button__icon--plus bx bx-plus"
          onClick={() => arrayHelpers.insert(index, getNewInputParameter())}
        />
      </div>
    </div>
  );
};

InputParameter.defaultProps = { index: 0 };
