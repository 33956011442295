import { FC, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
} from 'reactstrap';
import { getAllSchemes } from 'store/powerCalculator/powerCalculator.actions';
import { useAppDispatch } from 'hooks/redux';
import { ModalHeader } from 'components/ModalHeader/ModalHeader';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';

interface Props {
  isOpen: boolean;
  close: () => void;
  create: () => void;
}
interface IError {
  type: string;
  message: string;
}

const PowerCalculatorCreateForm: FC<Props> = ({ isOpen, close }) => {
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<null | IError>(null);

  const dispatch = useAppDispatch();


  const handleClose = () => {
    close();
    setName('');
  };
  const handleSubmit = async () => {
    try {
      await PowerCalculatorService.createScheme(name);
      dispatch(getAllSchemes());
      close();
      setName('');

      
    } catch (error) {
      console.log(error);
      if (typeof error === 'string') {
        setError({ message: error, type: 'server' });
      }
      setError({
        message: 'Произошла ошибка. Попробуйте позже',
        type: 'server',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
    >
      <ModalHeader closeHandler={close} title='Добавить схему' />
      <ModalBody>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <FormGroup>
            <div className='input__title'>Название схемы:</div>
            <Input
              required
              name='name'
              onChange={({ target }) => setName(target.value)}
              value={name}
            />
          </FormGroup>

          <FormGroup className='text-end mt-4'>
            <Button color='primary' outline type='button'  className='me-3' onClick={handleClose}>
              Отмена
            </Button>
            <Button
              color='primary'
              type='submit'
            >
              Сохранить
            </Button>
          </FormGroup>
          {!!error && error.type === 'server' && (
            <Alert color='danger'>{error.message}</Alert>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default PowerCalculatorCreateForm;
