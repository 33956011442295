import './ResourceLimitation.scss';

import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { useField } from 'formik';
import { FC } from 'react';

import { ResourceLimitationProps } from './types';

export const ResourceLimitation: FC<ResourceLimitationProps> = ({
  recurseName,
}) => {
  const [{ value: useLimitation }, , { setValue: setLimitation }] = useField(
    `${recurseName}UseRestrictions`
  );
  const minFieldName = `${recurseName}Min`;
  const maxFieldName = `${recurseName}Max`;

  const [{ value: minValue }] = useField(minFieldName);
  const [{ value: maxValue }] = useField(maxFieldName);

  if (useLimitation) {
    return (
      <div className="resource-limitation">
        <span>от</span>
        <FieldNumberInput
          type="numberInput"
          label=""
          name={minFieldName}
          style={{ width: `${minValue}`.length + 50 + 'px' }}
          className="resource-limitation__value"
          isShowError={false}
        />
        <span>до</span>
        <FieldNumberInput
          type="numberInput"
          label=""
          name={maxFieldName}
          style={{ width: `${maxValue}`.length + 50 + 'px' }}
          className="resource-limitation__value"
          isShowError={false}
        />
        <span
          className="btn-icon bx bx-x"
          onClick={() => setLimitation(false)}
        />
      </div>
    );
  }

  return (
    <div className="resource-limitation">
      <span
        className="btn-icon bx bx-pencil"
        onClick={() => setLimitation(true)}
      >
        <span className="button-text"> Ограничения</span>
      </span>
    </div>
  );
};
