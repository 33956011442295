import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IRegisterData } from 'types/user'
import UserService from './users.service'

export const register = createAsyncThunk(
  'auth/register',
  async (data: IRegisterData, { rejectWithValue }) => {
    try {
      const response = await UserService.register(data)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error!.response!.data?.message[0] ?? 'Произошла ошибка')
      }
      return rejectWithValue('Произошла ошибка попробуйте позже')
    }
  }
)

export const getAllUsers = createAsyncThunk('users/all', async () => {
  const response = await UserService.getAllUsers()
  return response.data
})

interface IEditUserData {
  userData: IRegisterData
  userId: number
}

export const editUser = createAsyncThunk(
  'users/edit',
  async ({ userData, userId }: IEditUserData, { rejectWithValue }) => {
    try {
      const response = await UserService.editUser(userData, userId)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error!.response!.data?.message[0] ?? 'Произошла ошибка')
      }
      return rejectWithValue('Произошла ошибка попробуйте позже')
    }
  }
)

export const deleteUser = createAsyncThunk('users/delete', async (userId: number) => {
  await UserService.deleteUser(userId)
  return userId
})
