import {
  Handle  as XyHandle ,
  Node,
  NodeProps,
  Position,
  useConnection,
} from '@xyflow/react';
import { ReactComponent as Broker } from 'assets/sass/icons/broker.svg';
import { useAppDispatch } from 'hooks/redux';
import { memo } from 'react';
import { setSelectedNode } from 'store/powerCalculator/powerCalculator.actions';
import { NodeData } from 'types/powerCalculator';

const BrokerNode = (props: NodeProps<Node<NodeData>>) => {
  const dispatch = useAppDispatch();
  const { id, data } = props;

  const connection = useConnection();
  const isTarget = connection.inProgress && connection.fromNode.id !== id;

  const handleSelect = () => {
    dispatch(setSelectedNode(props));
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div data-cy={data.name} className="node handle" onClick={handleSelect}>
      <div className="node__icon">{<Broker />}</div>

      {!connection.inProgress && (
          // @ts-ignore
        <XyHandle
          className="custom-handle"
          position={Position.Right}
          type="source"
        />
      )}
      {(!connection.inProgress || isTarget) && (
        // @ts-ignore
        <XyHandle
          className="custom-handle"
          position={Position.Left}
          type="target"
          isConnectableStart={false}
        />
      )}

      <span className="node__name"> {data.name}</span>
    </div>
  );
};

export default memo(BrokerNode);
