import './LeftComparisonParameter.scss';

import { FieldSelect } from 'components/FormFieldsBuilder/fields/FieldSelect';
import { useAppSelector } from 'hooks/redux';
import { FC } from 'react';
import { getPowerCalculatorSchemesAsOptions } from 'store/powerCalculator/powerCalculator.selectors';

import { LeftComparisonParameterProps } from './types';

export const LeftComparisonParameter: FC<LeftComparisonParameterProps> = ({
  name,
  label,
}) => {
  const constructorInputParams = useAppSelector(
    getPowerCalculatorSchemesAsOptions
  );

  return (
    <div className="left-comparison-parameter">
      <FieldSelect
        type="select"
        name={name}
        options={constructorInputParams}
        label={label || 'Параметр сравнения'}
        isCreatable={false}
        placeholder="Выбрать параметр"
      />
    </div>
  );
};
