import { FC, useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import shapesImg from 'assets/images/shapes.svg'
import { ISchemeData, PowerCalculatorModalType } from 'types/powerCalculator'
import { useNavigate } from "react-router-dom";

interface Props {
  scheme:ISchemeData,
  openModalHandler: (modalType: PowerCalculatorModalType, scheme: ISchemeData) => void
}

const PowerCalculatorsDropDown: FC<Props> = ({ scheme, openModalHandler }) => {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false)

  const toggleHandler = () => {
    setIsOpen(!isOpen)
  }


  return (
    <div className="text-center">
      <Dropdown toggle={toggleHandler} isOpen={isOpen}>
        <DropdownToggle className="dropdown__btn">
          <img src={shapesImg} alt="" />
        </DropdownToggle>
        <DropdownMenu className="table__dropdown_list">
          <DropdownItem
            color="secondary"
            className="table__dropdown_item_edit"
            onClick={() => {
              navigate(`/calculator_scheme/${scheme.id}`)
            }}
          >
            Редактировать
          </DropdownItem>
          <DropdownItem
            className="table__dropdown_item_delete"
            onClick={() => openModalHandler('delete', scheme)}
          >
            Удалить
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default PowerCalculatorsDropDown
