import React from 'react'
import logoImg from 'assets/images/logo.svg'
import './Header.scss'
import { Container } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import keyListImg from 'assets/images/key-list.svg'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import classNames from 'classnames'
import { displayRoles } from 'types/user'
import logoutImg from 'assets/images/logout.svg'
import { logout } from 'store/auth/auth.actions'

const Header = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  const { name, role } = useAppSelector((state) => ({
    name: state.auth.name,
    role: state.auth.role || '',
  }))

  return (
    <div className="header__wrapper">
      <Container className="h-100" fluid>
        <div className="header__inner">
          <div className="header__left">
            <Link to="/licenses" className="header__logo_wrapper">
              <img src={logoImg} alt="" />
            </Link>
            <div className="header__nav">
              {role === 'admin' && (
                <Link
                  className={classNames({
                    header__link_active: pathname === '/users',
                  })}
                  to={'/users'}
                >
                  <i className="bx bx-user-circle" style={{ fontSize: '24px' }}></i>
                  Пользователи
                </Link>
              )}
              <Link
                className={classNames({
                  header__link_active: pathname === '/certificates',
                })}
                to={'/certificates'}
              >
                <i className="bx bx-copy-alt" style={{ fontSize: '24px' }}></i>
                Сертификаты
              </Link>
              <Link
                className={classNames({
                  header__link_active: pathname === '/licenses',
                })}
                to={'/licenses'}
              >
                <img src={keyListImg} alt="" />
                Лицензионные ключи
              </Link>
              {role === 'admin' && (
                <Link
                  className={classNames({
                    header__link_active: pathname.includes('/calculator_scheme'),
                  })}
                  to={'/calculator_schemes'}
                >
                   <i className="bx bx-calculator" style={{ fontSize: '24px' }}></i>
                   Конфигуратор ресурсов
                </Link>
              )}
              <Link
                className={classNames({
                  header__link_active: pathname.includes('/power_calculators'),
                })}
                to={'/power_calculators'}
              >
                     <i className="bx bx-sitemap" style={{ fontSize: '24px' }}></i>
                     Калькулятор мощности
              </Link>
            </div>
            <div></div>
          </div>
          <div className="header__right">
            <span className="letter__circle">{name && name[0]}</span>
            <div className="header__right_name_wrapper">
              <div>
                <div className="header__right_name">{name}</div>
                <div className="header__right_role">{displayRoles[role]}</div>
              </div>
              <img className="cursor-pointer" src={logoutImg} alt="" onClick={logoutHandler} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Header
