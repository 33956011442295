import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

const selectCertificate = (state: RootState) => state.certificates

export const getCertificatesItemsSelector = createSelector(
  selectCertificate,
  (state) => state.items
)

export const getCertificatesCompaniesSelector = createSelector(selectCertificate, (state) => {
  const companies: string[] = []

  for (const license of state.items) {
    const company = license.company
    if (!companies.includes(company)) companies.push(company)
  }
  return companies
})

export const getCertificatesAuthorSelector = createSelector(selectCertificate, (state) => {
  const authors: string[] = []

  for (const license of state.items) {
    if (license.user) {
      const name = license.user.name
      if (!authors.includes(name)) authors.push(name)
    }
  }
  return authors
})

export const getCertificatesTableSelector = createSelector(
  selectCertificate,
  getCertificatesItemsSelector,
  getCertificatesCompaniesSelector,
  getCertificatesAuthorSelector,
  (_state, items, companies, authors) => ({
    items,
    companies,
    authors,
  })
)
