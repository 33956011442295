import { stringField } from 'utils/validation';
import * as yup from 'yup';

const numberField = yup
  .number().required('Обязательное')
  .min(0, 'Минимальное значение 0');



const parameterValidation =
yup.array().of(
  yup.object().shape(
    {
      value: stringField,
      label: stringField,
      rate: numberField
    }
  )
)
   
export const inputParamsValidation =  
  yup
  .object()
  .shape({
    vmCost:  yup.object().shape(
      {
        cpu: numberField,
        ram: numberField,
        hdd: numberField,
        gpu: numberField,
      }
    ),
    defInputParams:  parameterValidation,
    inputParams: parameterValidation,
  })


