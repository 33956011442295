import Layout from 'components/Layout/Layout'

const RouteElement = ({ component: Component, layout = true }) => {
  if (!layout) {
    return <Component />
  }

  return (
    <>
      <Layout>
        <Component />
      </Layout>
    </>
  )
}

export default RouteElement
