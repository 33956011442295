import {
  getOutgoers,
  Handle as XyHandle,
  Node,
  NodeProps,
  Position,
  useConnection,
  useReactFlow,
} from '@xyflow/react';
import { useAppDispatch } from 'hooks/redux';
import { setSelectedNode } from 'store/powerCalculator/powerCalculator.actions';
import { NodeData } from 'types/powerCalculator';
import { calculatePath } from 'utils/reactFlow';

const BranchNode = (props: NodeProps<Node<NodeData>>) => {
  const { getEdges, getNodes } = useReactFlow();
  const lenOutgoesEdges = getOutgoers(props, getNodes(), getEdges()).length;

  const height = 80;
  const width = 150;

  const connection = useConnection();
  const isTarget = connection.inProgress && connection.fromNode.id !== props.id;
  const dispatch = useAppDispatch();

  const handleSelect = () => {
    dispatch(setSelectedNode(props));
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="branching handle" onClick={handleSelect}>
      <div />
      {/* <NodeResizer onResize={onResize} color="#ff0071" isVisible={selected} /> */}
      <svg width={width} height={height} className="shape-svg">
        <g transform="translate(2, 2)">
          <path
            d={calculatePath(width - 4, height - 4)} // Генерация пути с учетом отступов
            fill="#fdd854"
            strokeWidth="3"
            stroke="#2a3042"
            fillOpacity="0.8"
          />
        </g>
      </svg>
      {!connection.inProgress && (
    /*
      // @ts-ignore */
        <XyHandle
          className="custom-handle"
          position={Position.Right}
          type="source"
          isConnectableStart={lenOutgoesEdges < 2}
        />
      )}

      {(!connection.inProgress || isTarget) && (
     /*
      // @ts-ignore */
        <XyHandle
          className="custom-handle"
          position={Position.Left}
          type="target"
          isConnectableStart={false}
        />
      )}
     {/* @ts-ignore */} 
      <XyHandle
        type="source"
        position={Position.Left}
        className="custom-handle"
        isConnectableStart={lenOutgoesEdges < 2}
      />
      {/* @ts-ignore */} 
      <XyHandle
        type="source"
        position={Position.Right}
        className="custom-handle"
        isConnectableStart={lenOutgoesEdges < 2}
      />
      <span className="node__name"> {props.data.name}</span>
    </div>
  );
};

export default BranchNode;
