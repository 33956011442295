import React, { useState } from 'react'
import './Login.scss'
import logoImg from 'assets/images/logo.svg'
import { Alert, Button, Form, Input } from 'reactstrap'
import { useAppDispatch } from 'hooks/redux'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { login } from 'store/auth/auth.actions'

const Login = () => {
  const [error, setError] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: async (values) => {
      dispatch(login(values))
        .unwrap()
        .then(() => navigate('/'))
        .catch((error) => {
          if (typeof error === 'string') {
            return setError(error)
          }
          setError('Произошла ошибка. Попробуйте позже')
        })
    },
  })

  return (
    <div className="login__wrapper">
      <div className="login__form">
        <div className="login__form_header">
          <div className="login__form_title_wrapper">
            <div className="login__form__title">Добро пожаловать!</div>
            <div className="login__form__subtitle">Войдите в систему, чтобы продолжить работу</div>
          </div>
          <div className="login__form_logo">
            <img src={logoImg} alt="" />
          </div>
        </div>
        <div className="login__form_body">
          <Form className="login__form_inner" onSubmit={handleSubmit}>
            <div>
              Логин
              <Input
                name="login"
                onChange={handleChange}
                className="login__input"
                placeholder="Введите логин"
              />
            </div>
            <div className="mt-3">
              Пароль
              <Input
                type="password"
                name="password"
                onChange={handleChange}
                className="login__input"
                placeholder="Введите пароль"
              />
            </div>
            <Button type="submit" className="login__btn" color="primary">
              Войти
            </Button>
            {error && (
              <Alert color="danger" className="mt-3 mb-0">
                {error}
              </Alert>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Login
