import './VirtualMachineCost.scss';

import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { FC } from 'react';
import { BaseParamsData } from 'types/powerCalculator';
import { VirtualMachineCostProps } from './types';


export const VirtualMachineCost: FC<VirtualMachineCostProps> = () => {
  const renderResourceInputs = () => (
    <>
      {[
        { label: 'CPU (%)', name: 'vmCost.cpu' },
        { label: 'RAM (Мб)', name: 'vmCost.ram' },
        { label: 'HDD (Мб)', name: 'vmCost.hdd' },
        { label: 'GPU (кол-во)', name: 'vmCost.gpu' },
      ].map((input) => (
        <div className="virtual-machine-cost__parameter" key={input.name}>
          <FieldNumberInput<BaseParamsData>
            type="numberInput"
            label={input.label}
            name={input.name}
            isShowError
          />
        </div>
      ))}
    </>
  );


  return (
    <div className="virtual-machine-cost">
      <span>Накладные расходы на VM</span>
      <div className="virtual-machine-cost__params">
        {renderResourceInputs()}
      </div>

    </div>
  );
};
