import classNames from 'classnames'
import { ICertificate } from 'types/certificate'
import { LicenseValidityType } from 'types/license'
import { IUserData } from 'types/user'

const checkValidityDate = (validity: LicenseValidityType, firstDateArg: string) => {
  if (validity === 'indefinitely') return true
  let secondDate: Date | number = new Date(firstDateArg)
  secondDate.setFullYear(secondDate.getFullYear() + 1)
  secondDate = Date.parse(secondDate.toDateString())
  const checkedDate = Date.parse(new Date().toDateString())
  const firstDate = Date.parse(new Date(firstDateArg).toDateString())

  if (checkedDate <= secondDate && checkedDate >= firstDate) return true
  return false
}

export const certificatesColumns = [
  {
    dataField: 'status',
    headerClasses: 'status__tr',
    formatter(_, row: ICertificate) {
      const classname = checkValidityDate(row.validity, row.validityStart)
        ? 'status__active'
        : 'status__expired'
      return (
        <div className="status__wrapper">
          <div className={classNames('status', classname)}></div>
        </div>
      )
    },
    text: 'Статус',
  },
  {
    dataField: 'company',
    text: 'Партнер',
  },
  {
    dataField: 'pin',
    text: 'PIN',
  },
  {
    dataField: 'createdAt',
    text: 'Дата передачи',
    formatter(cell: Date) {
      const date = new Date(cell)
      return <>{date.toLocaleDateString()}</>
    },
  },
  {
    dataField: 'validity',
    text: 'Период действия лицензии',
    formatter(_, row: ICertificate) {
      const firstDate = new Date(row.validityStart)
      let secondDate: 'бессрочно' | 'year' | '' | Date | string =
        row.validity === 'indefinitely'
          ? 'бессрочно'
          : row.validityEnd
          ? row.validityEnd
          : row.validity === 'year'
          ? 'year'
          : ''

      if (secondDate !== 'year' && secondDate && secondDate !== 'бессрочно') {
        secondDate = new Date(secondDate)
        secondDate = secondDate.toLocaleDateString()
      }

      if (secondDate === 'year') {
        secondDate = new Date(firstDate)
        secondDate.setFullYear(secondDate.getFullYear() + 1)
        secondDate = secondDate.toLocaleDateString()
      }

      return (
        <>
          с <span className="text-decoration-underline">{firstDate.toLocaleDateString()}</span> по{' '}
          <span className="text-decoration-underline">{secondDate}</span>
        </>
      )
    },
  },
  {
    dataField: 'user',
    text: 'Ответственный',
    formatter(cell: IUserData) {
      return <>{cell.name}</>
    },
  },
]
