import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'constants/api_endpoints'
import { CalculateResponse, ISchemeData } from 'types/powerCalculator'
import axiosApi from 'utils/api_helper'

export default class PowerCalculatorService {
  static async getAllSchemes() {
    const response: AxiosResponse<ISchemeData[]> = await axiosApi.get(`${ENDPOINTS.SCHEMES}`)

    return response
  }

  static async getScheme(schemeId: string){
    const response: AxiosResponse<ISchemeData> = await axiosApi.get(`${ENDPOINTS.SCHEMES}/${schemeId}`)

    return response?.data.scheme
  }

  static async createScheme(schemeDescription: string) {
    const data: Omit<ISchemeData, 'id' | 'createdAt'> = {
      description: schemeDescription,
      scheme: {
        nodes: [],
        edges: [],
        inputParams: [],
        defInputParams: [{ "rate": 0, "label": "максимальная  загруженность по RAM", "value": "maxDownloadRam" }, { "rate": 0, "label": "максимальная загруженность по CPU", "value": "maxDownloadCpu" }],
        vmCost: {
          cpu: 0,
          ram: 0,
          hdd: 0,
          gpu: 0
        }
      }
    }

    const response: AxiosResponse<ISchemeData> = await axiosApi.post(ENDPOINTS.SCHEMES, data)
    return response
  }


  static async updateScheme(schemeId: string, scheme: Omit<ISchemeData['scheme'], 'id' | 'createdAt' | 'description'>) {
    const response: AxiosResponse<ISchemeData> = await axiosApi.put(`${ENDPOINTS.SCHEMES}/${schemeId}`, scheme)
    return response
  }

  static async deleteScheme(schemeId: string) {
    const response: AxiosResponse<ISchemeData> = await axiosApi.delete(`${ENDPOINTS.SCHEMES}/${schemeId}`)
    return response
  }

  static async calculateScheme(data: {schemeId: string, inputParams: Record<string, number>}) {
    const response: AxiosResponse<CalculateResponse> = await axiosApi.post(`${ENDPOINTS.SCHEMES}/calculate/${data.schemeId}`, data)
    return response.data || null
  }
}
