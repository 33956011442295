import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { editUser } from 'store/users/users.actions'
import { IUserData, UserRoleType } from 'types/user'
import { fetchUserData, login, logout } from './auth.actions'

interface IInitState {
  isAuth: boolean
  login?: string
  name?: string
  role?: UserRoleType
  id?: number
}

const initState: IInitState = {
  isAuth: false,
}
const authSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, action) => ({
      ...action.payload,
      isAuth: true,
    }))
    builder.addCase(fetchUserData.fulfilled, (state, action: PayloadAction<IUserData>) => ({
      ...action.payload,
      isAuth: true,
    }))
    builder.addCase(editUser.fulfilled, (state, action) => {
      if (action.payload.id === state.id) {
        return { ...action.payload, isAuth: true }
      }
    })
    builder.addCase(logout.fulfilled, () => ({ isAuth: false }))
  },
})

export const authReducer = authSlice.reducer

export default authSlice
