import './FormFieldLabel.scss';

import { FC } from 'react';

import { FormFieldLabelProps } from './types';

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
  htmlFor,
  children,
}) => {
  return (
    <label htmlFor={htmlFor} className="form-field-label">
      {children}
    </label>
  );
};
