import './Tabs.scss';

import cls from 'classnames';
import { FC } from 'react';
import { Tabs as BSTabs } from 'react-bootstrap';

import { TabsProps } from './types';

export const Tabs: FC<TabsProps> = (props) => {
  return (
    <BSTabs
      {...props}
      className={cls('tabs', { [String(props.className)]: !!props.className })}
      mountOnEnter
    >
      {props.children}
    </BSTabs>
  );
};
