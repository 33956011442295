import './InputParamsWithDefValue.scss';

import { FieldBase } from 'components/FormFieldsBuilder/fields/FieldBase';
import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { useField } from 'formik';
import { FC } from 'react';
import { InputParameterData } from 'types/powerCalculator';

import { InputParamsWithDefValueProps } from './types';

export const InputParamsWithDefValue: FC<InputParamsWithDefValueProps> = () => {
  const [{ value: listDefParams }] =
    useField<InputParameterData[]>('defInputParams');

  return (
    <div className="input-params-with-def-value">
      {listDefParams.map((parameter, index: number) => (
        <div
          key={parameter.value}
          className="input-params-with-def-value__item"
        >
          <div className="input-params-with-def-value__field">
            <FieldBase
              name={`defInputParams.${index}.label`}
              label=""
              type="text"
              placeholder="Введите название поля"
            />
          </div>
          <div className="input-params-with-def-value__field">
            <FieldNumberInput
              name={`defInputParams.${index}.rate`}
              label=""
              type="numberInput"
              placeholder="Введите значение"
              isShowError
            />
          </div>
        </div>
      ))}
    </div>
  );
};
