import './BaseParams.scss';

import { Tabs } from 'components/Tabs';
import { FormFieldCodeEditor } from 'components/FormFieldsBuilder/components/FormFieldCodeEditor';
import { FieldBase } from 'components/FormFieldsBuilder/fields/FieldBase';
import { FieldNumberInput } from 'components/FormFieldsBuilder/fields/FieldNumberInput';
import { FC } from 'react';
import { Tab } from 'react-bootstrap';
import { BaseParamsData } from 'types/powerCalculator';


import { BaseParamsProps } from './types';
import { ResourceLimitation } from './components/ResourceLimitation/ResourceLimitation';
import { LeftComparisonParameter } from 'modules/LeftComparisonParameter';

export const BaseParams: FC<BaseParamsProps> = ({ children }) => {
  return (
    <div className="base-params">
      <div className="base-params__item">
        <FieldBase<BaseParamsData>
          type="text"
          label="Название узла"
          name="name"
        />
      </div>

      <div className="base-params__item">
        <ResourceLimitation recurseName="cpu" />
        <FieldNumberInput<BaseParamsData>
          type="numberInput"
          label="CPU (%)"
          name="cpu"
          isShowError
        />
      </div>
      <div className="base-params__item">
        <ResourceLimitation recurseName="ram" />
        <FieldNumberInput<BaseParamsData>
          type="numberInput"
          label="RAM (Мб)"
          name="ram"
          isShowError
        />
      </div>

      <div className="base-params__item">
        <ResourceLimitation recurseName="hdd" />
        <FieldNumberInput<BaseParamsData>
          type="numberInput"
          label="HDD (Мб)"
          name="hdd"
          isShowError
        />
      </div>
      <div className="base-params__item">
        <ResourceLimitation recurseName="gpu" />
        <FieldNumberInput<BaseParamsData>
          type="numberInput"
          label="GPU (кол-во)"
          name="gpu"
          isShowError
        />
      </div>
      <div className="base-params__item">
      <LeftComparisonParameter
          label="Показатель для расчета мощности"
          name="parameterNameForCalculatePower"
        />
      </div>
      <div className="base-params__item">
        <FieldBase<BaseParamsData>
          type="text"
          label="Название предпочитаемой VM"
          name="namePreferredVm"
        />
      </div>
      <div className="base-params__item">
        <FieldBase<BaseParamsData>
          type="text"
          label="Группа виртуальных машин"
          name="groupVM"
        />
      </div>
      
      {children}
      <Tabs defaultActiveKey="CPU">
        <Tab id="CPU" eventKey="CPU" title="CPU">
          <FormFieldCodeEditor name="cpuCode" defCode={''} />
        </Tab>
        <Tab id="RAM" eventKey="RAM" title="RAM">
          <FormFieldCodeEditor name="ramCode" defCode={''} />
        </Tab>
        <Tab id="HDD" eventKey="HDD" title="HDD">
          <FormFieldCodeEditor name="hddCode" defCode={''} />
        </Tab>
        <Tab id="GPU" eventKey="GPU" title="GPU">
          <FormFieldCodeEditor name="gpuCode" defCode={''} />
        </Tab>
      </Tabs>
    </div>
  );
};
