import './PowerCalculator.scss';

import { useState } from 'react';
import { CalculateResponse } from 'types/powerCalculator';
import PowerCalculatorService from 'store/powerCalculator/powerCalculator.service';

import PowerCalculatorResultTable from './components/PowerCalculatorResultTable/PowerCalculatorResultTable';
import { Card, CardBody } from 'reactstrap';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import PowerCalculatorForm from './components/PowerCalculatorForm/PowerCalculatorForm';



const PowerCalculator = () => {
  const [response, setResponse] = useState<null | CalculateResponse>(null);


  const handelCalculate = async (data: { schemeId: string, inputParams: Record<string, number> }) => {
    const res = await PowerCalculatorService.calculateScheme(data)
    setResponse(res)
  }

  return (
    <> 
     <BreadCrumbs secondItem="Калькулятор" title="Калькулятор мощности" />
      <div className="power-calculator">

        {<PowerCalculatorForm handelCalculate={handelCalculate} />}
        {response ? <PowerCalculatorResultTable data={response} /> :
          <Card className="border-0 flex-grow-1 mt-3">
            <CardBody className="d-flex flex-column align-items-center justify-content-center">
              Для расчета мощности необходимо выбрать нужную схему и заполнить параметры
            </CardBody>
          </Card>}
      </div>
    </>

  );
};

export default PowerCalculator;
