import './InputParameterControl.scss';

import { useField } from 'formik';
import { FC } from 'react';

import { InputParameterControlProps } from './types';

export const InputParameterControl: FC<InputParameterControlProps> = () => {
  const [{ value: isEdit }, , { setValue }] = useField('isEdit');

  return (
    <div className="input-parameter-control">
      <span
        className={`btn-icon button__icon bx ${isEdit ? 'bx-x' : 'bx-pencil'}`}
        onClick={() => setValue(!isEdit)}
      >
        <span> Входящие параметры</span>
      </span>
    </div>
  );
};
