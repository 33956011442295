import './ScalingCondition.scss';

import { LeftComparisonParameter } from 'modules/LeftComparisonParameter';
import { RadioField } from 'modules/RadioField';
import { RightComparisonParameter } from 'modules/RightComparisonParameter';
import { FC } from 'react';

import { ScalingConditionProps } from './types';
import { newScalingConditionData } from 'modules/ScalingConditions/constants';

export const ScalingCondition: FC<ScalingConditionProps> = ({
  arrayHelpers,
  scope,
  index,
}) => {
  return (
    <>
      {!!index && <RadioField scope={`${scope}`} />}
      <div className="scaling-condition">
        <LeftComparisonParameter name={`${scope}.leftOperand`} />
        <RightComparisonParameter
          name={`${scope}.rightOperand`}
          label="Реплицировать каждые:"
        />

        <div className="scaling-condition__controls">
          <span
            className="btn-icon button__icon button__icon--trash bx bx-trash"
            onClick={() => arrayHelpers.remove(index)}
          />
          <span
            className="btn-icon button__icon button__icon--plus bx bx-plus"
            onClick={() => arrayHelpers.insert(index, newScalingConditionData)}
          />
        </div>
      </div>
    </>
  );
};
