import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { useAppSelector } from 'hooks/redux'
import { useCertificates } from 'hooks/useCertificates'
import { useModal } from 'hooks/useModal'
import React, { lazy, Suspense, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { ICertificate } from 'types/certificate'
import CertificatesDeleteModal from './components/CertificatesDeleteModal/CertificatesDeleteModal'
// import CertificatesEditModal from './components/CertificatesEditModal/CertificatesEditModal'
import CertificatesTable from './components/CertificatesTable/CertificatesTable'

const CertificatesEditModal = lazy(
  () => import('./components/CertificatesEditModal/CertificatesEditModal')
)

const CertificatesExpandModal = lazy(
  () => import('./components/CertificatesExpandModal/CertificatesExpandModal')
)

const Certificates = () => {
  const {
    createOrUpdateCertificate,
    deleteCertificate,
    downloadFile,
    getCertificates,
    expandCertificate,
  } = useCertificates()
  const { closeModal, modal, openModal } = useModal<
    'delete' | 'edit' | 'expand' | false,
    ICertificate
  >()
  const selectedItem =
    useAppSelector((state) =>
      state.certificates.items.find((item) => item.id === modal.item?.id)
    ) || null

  const deleteHandler = async () => {
    await deleteCertificate(modal.item?.id ?? 0)
    closeModal()
  }

  useEffect(() => {
    getCertificates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BreadCrumbs title="Сертификаты" secondItem="Список" />
      <Card className="border-0 flex-grow-1 mt-4">
        <CardBody>
          <CertificatesTable downloadFile={downloadFile} openModal={openModal} />
        </CardBody>
      </Card>
      <Suspense>
        <CertificatesEditModal
          closeHandler={closeModal}
          createOrUpdateCertificate={createOrUpdateCertificate}
          editItem={selectedItem}
          isOpen={modal.isOpen === 'edit'}
        />
        <CertificatesExpandModal
          closeHandler={closeModal}
          isOpen={modal.isOpen === 'expand'}
          item={selectedItem}
          saveHandler={expandCertificate}
        />
      </Suspense>
      <CertificatesDeleteModal
        company={modal.item?.company ?? ''}
        createdAt={modal.item?.createdAt ?? ''}
        closeHandler={closeModal}
        deleteHandler={deleteHandler}
        isOpen={modal.isOpen === 'delete'}
      />
    </>
  )
}

export default Certificates