import { ModalHeader } from 'components/ModalHeader/ModalHeader'
import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Modal, ModalBody } from 'reactstrap'
import { displayRoles, IRegisterData, IUserData, UserRoleType } from 'types/user'
import { IModalState } from '../Users'
import './UserModal.scss'

interface Props {
  modalState: IModalState
  close: () => void
  registerUser: (values: IRegisterData) => Promise<IUserData>
  updateUser: (values: IRegisterData, userId: number) => Promise<IUserData>
}

interface IError {
  type: string
  message: string
}

const UserModal: FC<Props> = ({
  modalState: { isOpen, item: user },
  close,
  registerUser,
  updateUser,
}) => {
  const [error, setError] = useState<null | IError>(null)

  const { handleChange, handleSubmit, resetForm, setValues, values, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      role: UserRoleType.user,
      login: '',
      password: '',
    },

    async onSubmit(values) {
      try {
        if (!user?.id) {
          await registerUser(values)
        } else {
          await updateUser(values, user.id)
        }
        return closeHandler()
      } catch (error) {
        console.log(error)
        if (typeof error === 'string') {
          setError({ message: error, type: 'login' })
        }
        setError({
          message: 'Произошла ошибка. Попробуйте позже',
          type: 'server',
        })
      }
    },
  })

  useEffect(() => {
    if (isOpen && user) {
      setValues({
        ...user,
        password: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOpen])

  const closeHandler = () => {
    close()
    setError(null)
    resetForm()
  }

  const generatePassword = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const passwordLength = 12
    let password = ''
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length)
      password += chars.substring(randomNumber, randomNumber + 1)
    }
    setFieldValue('password', password)
  }

  return (
    <Modal isOpen={isOpen === 'edit'} toggle={closeHandler} centered className="users__modal">
      <ModalHeader
        closeHandler={closeHandler}
        title={!!user ? 'Редактировать пользователя' : 'Добавить пользователя'}
      />
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <div className="input__title">ФИО:</div>
            <Input required name="name" onChange={handleChange} value={values.name} />
          </FormGroup>
          <FormGroup>
            <div className="input__title">Выбор роли:</div>
            <Input name="role" type="select" onChange={handleChange} value={values.role}>
              {Object.values(UserRoleType).map((type) => (
                <option key={type} value={type}>
                  {displayRoles[type]}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <div className="input__title">Логин:</div>
            <Input
              required
              invalid={!!error && error.type === 'login'}
              name="login"
              onChange={handleChange}
              value={values.login}
            />
            {!!error && error.type === 'login' && (
              <FormFeedback color="dangerous">{error.message}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <div className="input__title">Пароль:</div>
            <div className="d-flex justify-content-between align-items-center">
              <Input
                required={!user}
                className="users__modal_password_input"
                name="password"
                value={values.password}
                onChange={handleChange}
              />
              <Button
                className="users__modal_password_btn"
                outline
                color="primary"
                type="button"
                onClick={generatePassword}
              >
                Сгенерировать пароль
              </Button>
            </div>
          </FormGroup>
          <FormGroup className="text-end mt-4 users__modal_btns_wrapper">
            <Button color="primary" outline type="button" onClick={closeHandler}>
              Отмена
            </Button>
            <Button className="users__modal_submit_btn" color="primary" type="submit">
              Сохранить
            </Button>
          </FormGroup>
          {!!error && error.type === 'server' && <Alert color="danger">{error.message}</Alert>}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default UserModal
