import { displayRoles } from 'types/user'

export const usersColumns = [
  {
    dataField: 'id',
    formatter(_, row) {
      return <div className="letter__circle">{row.name[0] || ''}</div>
    },
    text: '#',
  },
  {
    dataField: 'name',
    text: 'ФИО',
  },
  {
    dataField: 'role',
    text: 'Роль',
    formatter(cell) {
      return <>{displayRoles[cell]}</>
    },
  },
  {
    dataField: 'createdAt',
    text: 'Дата создания',
    formatter(cell: Date) {
      const date = new Date(cell)
      return <>{date.toLocaleDateString()}</>
    },
  },
  {
    dataField: 'login',
    text: 'Логин',
  },
]
