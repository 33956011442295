import './Rules.scss';

import { Button } from 'reactstrap';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { RadioField } from 'modules/RadioField';
import React, { FC } from 'react';

import { RuleItem } from './components';
import { RulesProps } from './types';
import { newRuleData } from './constants';


export const Rules: FC<RulesProps> = ({ name }) => {
  const [{ value = [] }] = useField(name);

  const renderRulesList = (arrayHelpers: FieldArrayRenderProps) => {
    if (!value.length) {
      return (
        <Button onClick={() => arrayHelpers.unshift(newRuleData)}>
          Добавить правило
        </Button>
      );
    }

    return value.map((_, index: number) => (
      <React.Fragment key={index}>
        {!!index && <RadioField scope={`${name}.${index - 1}`} />}
        <RuleItem
          scope={`${name}.[${index}]`}
          index={index}
          arrayHelpers={arrayHelpers}
        />
      </React.Fragment>
    ));
  };

  return (
    <div className="rules">
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {renderRulesList(arrayHelpers)}
            <span className="form-error-message">{}</span>
          </>
        )}
      />
    </div>
  );
};
