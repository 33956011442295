import { createAsyncThunk } from '@reduxjs/toolkit'
import { ILicenseCreate } from 'types/license'
import LicensesService from './licenses.service'

export const createLicenseAction = createAsyncThunk(
  'licenses/add',
  async (data: ILicenseCreate) => {
    const response = await LicensesService.createLicense(data)
    return response.data
  }
)

interface IUpdateLicenseAction {
  license: ILicenseCreate
  licenseId: number
}

export const updateLicenseAction = createAsyncThunk(
  'licenses/update',
  async ({ license, licenseId }: IUpdateLicenseAction) => {
    const response = await LicensesService.updateLicense(license, licenseId)
    return response.data
  }
)

export const deleteLicenseAction = createAsyncThunk(
  'licenses/delete',
  async (licenseId: number) => {
    await LicensesService.deleteLicense(licenseId)
    return licenseId
  }
)

export const getAllLicenses = createAsyncThunk('licenses/all', async () => {
  const response = await LicensesService.getLicenses()
  return response.data
})
