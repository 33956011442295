import { ModalStateOpenType } from 'pages/Licenses/Licenses'
import React, { ChangeEvent, FC, useCallback, useEffect } from 'react'
import { Button, Input } from 'reactstrap'
import './LicensesTableSort.scss'

interface Props {
  companies: string[]
  authors: string[]
  sort: { author: string; company: string }
  openModal: (condition: ModalStateOpenType, item?: any) => void
  changeSortState: (value: { [key: string]: string }) => void
  btnTitle: string
  firstSortTitle: string
  secondSortTitle: string
}

const LicensesTableSort: FC<Props> = ({
  btnTitle,
  firstSortTitle,
  secondSortTitle,
  authors,
  companies,
  openModal,
  changeSortState,
  sort,
}) => {
  const changeSortHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name
    const targetValue = e.target.value
    const value = { [targetName]: targetValue }
    changeSortState(value)
  }

  const checkIfInputValueExists = useCallback(
    (items: string[], [key, value]: [string, string]) => {
      if (value === 'all') return
      for (const item of items) {
        if (item === value) return
      }
      return changeSortState({ [key]: 'all' })
    },
    [changeSortState]
  )

  useEffect(() => {
    checkIfInputValueExists(authors, ['author', sort.author])
    checkIfInputValueExists(companies, ['company', sort.company])
  }, [authors, companies, checkIfInputValueExists, sort.author, sort.company])

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-3">
        <div className="licenses__filter_group">
          <div className="filter__title">{firstSortTitle}</div>
          <Input
            type="select"
            name="company"
            className="filter__select"
            onChange={changeSortHandler}
          >
            <option value={'all'}>Все</option>
            {companies &&
              companies.map((item, index) => (
                <option key={`${item}_${index}`} value={item}>
                  {item}
                </option>
              ))}
          </Input>
        </div>
        <div className="licenses__filter_group">
          <div className="filter__title">{secondSortTitle}</div>
          <Input
            type="select"
            name="author"
            className="filter__select"
            value={sort.author}
            onChange={changeSortHandler}
          >
            <option value={'all'}>Все</option>
            {authors &&
              authors.map((item, index) => (
                <option key={`${item}_${index}`} value={item}>
                  {item}
                </option>
              ))}
          </Input>
        </div>
      </div>
      <Button className="page__btn" color="primary" onClick={() => openModal('edit')}>
        {btnTitle}
      </Button>
    </div>
  )
}

export default LicensesTableSort
