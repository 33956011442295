import React from 'react'
import { Container } from 'reactstrap'
import './Footer.scss'

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <Container fluid>2022 &copy; Lexicom</Container>
    </div>
  )
}

export default Footer
